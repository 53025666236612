<template>
  <div class="container-main-content signup-mail">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Vui lòng kiểm tra email</h4>
        <p class="f14 mb-2">
          Đường dẫn đặt lại mật khẩu đã được gửi đến <b>{{ email }}.</b>
        </p>
        <div class="content-inner__body-mail d-flex mb-4">
          <a href="https://mail.google.com" target="_blank">
            <div class="content-inner__body-mail-item me-4">
              <img src="../../assets/images/gmail.svg" class="me-2" />
              <span class="f14">Mở Gmail</span>
            </div>
          </a>
          <a href="https://outlook.office.com" target="_blank">
            <div class="content-inner__body-mail-item">
              <img src="../../assets/images/outlook.png" class="me-2" />
              <span class="f14">Mở Outlook</span>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      email: this.$route.query.email,
    };
  },
};
</script>
